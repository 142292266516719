import React from "react";
import "./Header.scss";
import { Link } from "react-router-dom";
import { Dropdown, Menu, Space } from "antd";
import mainlogo from "../assets/images/sree-balamurugan-industries.svg";

export let setHeader = false;

export default class Header extends React.Component {
  constructor() {
    super();
    this.state = {
      openMenu: false,
    };
  }
  componentDidMount() {
    window.onscroll = function () {
      myFunction();
    };

    var header = document.getElementById("main-hearder");
    var sticky = header.offsetTop;

    function myFunction() {
      if (window.pageYOffset > sticky) {
        header.classList.add("scrolled");
      } else {
        header.classList.remove("scrolled");
      }
    }
    //Onscroll sticky Header function ends here
  }

  render() {
    return (
      <React.Fragment>
        <div className="main-hearder" id="main-hearder">
          <div className="container">
            <div className="top-header">
              <div className="logo pr-3">
                <Link to="/">
                  <img
                    src={mainlogo}
                    alt="Sree Balamurugan Industries - All scrap Materials, Used Grinding wheels, Mill scale, MS Powder"
                  />
                </Link>
              </div>
              <div
                className={
                  this.state.openMenu ? "navigations opened" : "navigations"
                }
              >
                <div className="center-header">
                  <ul>
                    <li>
                      <Link
                        exact
                        onClick={() => this.setState({ openMenu: false })}
                        to={"/"}
                      >
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => this.setState({ openMenu: false })}
                        to={"/about-us"}
                      >
                        About us
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => this.setState({ openMenu: false })}
                        to={"./products-services"}
                      >
                        {" "}
                        Products & Services
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => this.setState({ openMenu: false })}
                        to={"./blog"}
                      >
                        Blog
                      </Link>
                    </li>
                  </ul>
                  <div className="contact-button">
                    <Link to={"./contact"} className="btn btn-primary">
                      Contact
                    </Link>
                  </div>
                </div>
              </div>

              <div
                id="hamburger"
                className={this.state.openMenu ? "menuopened" : ""}
                onClick={() =>
                  this.setState({ openMenu: !this.state.openMenu })
                }
              />
            </div>

            {/* bottom header starts */}

            {/* bottom header ends */}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
