import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "antd";
import TitleSection from "../../Components/TitleSection/TitleSection";
import grindingWheel from "../../assets/images/blog/grinding-wheel.jpg";
import componentGrindingwheel from "../../assets/images/blog/components-of-grinding-wheel.jpg";

export default class whatisGrindingwheel extends Component {
  render() {
    return (
      <React.Fragment>
        <TitleSection
          className="whatis-grindingwheel-bg"
          titleName="What is Grinding wheel and it’s Uses ?"
        />

        <section className="section-padding">
          <div className="container">
            <Row gutter={[10, 20]} align="center">
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 20 }}
                lg={{ span: 20 }}
              >
                <p>
                  A
                  <a
                    target="_blank"
                    href="http://localhost/balamurugan/used-grinding-wheels/"
                    rel="noopener"
                  >
                    &nbsp;<strong>grinding wheel</strong>&nbsp;
                  </a>
                  is a wheel composed of an&nbsp;
                  <a
                    target="_blank"
                    href="https://en.wikipedia.org/wiki/Abrasive"
                    rel="noopener"
                  >
                    abrasive
                  </a>
                  &nbsp;compound and used for various&nbsp;
                  <a
                    target="_blank"
                    href="https://en.wikipedia.org/wiki/Grinding_(abrasive_cutting)"
                    rel="noopener"
                  >
                    grinding (abrasive cutting)
                  </a>
                  &nbsp;and&nbsp;
                  <a
                    target="_blank"
                    href="https://en.wikipedia.org/wiki/Abrasive_machining"
                    rel="noopener"
                  >
                    abrasive machining
                  </a>
                  operations. Such wheels are used in&nbsp;
                  <a
                    target="_blank"
                    href="https://en.wikipedia.org/wiki/Grinding_machine"
                    rel="noopener"
                  >
                    grinding machines
                  </a>
                  .
                </p>
                <p>
                  The wheels are generally made from a{" "}
                  <a
                    target="_blank"
                    href="https://en.wikipedia.org/wiki/Composite_material"
                    rel="noopener"
                  >
                    composite material
                  </a>
                  &nbsp;consisting of coarse-particle aggregate pressed and
                  bonded together by a cementing matrix (called the&nbsp;
                  <em>bond</em>&nbsp;in grinding wheel terminology) to form a
                  solid, circular shape. Various profiles and cross sections are
                  available depending on the intended usage for the wheel.
                </p>
                <p>
                  They may also be made from a solid steel or aluminium disc
                  with particles bonded to the surface. Today most grinding
                  wheels are artificial composites made with artificial
                  aggregates, but the history of grinding wheels began with
                  natural composite&nbsp;
                  <a
                    target="_blank"
                    href="https://en.wikipedia.org/wiki/Rock_(geology)"
                    rel="noopener"
                  >
                    stones
                  </a>
                  , such as those used for&nbsp;
                  <a
                    target="_blank"
                    href="https://en.wikipedia.org/wiki/Millstone"
                    rel="noopener"
                  >
                    millstones
                  </a>
                  .
                </p>
                <iframe
                  src="https://www.youtube.com/embed/ggMi0Ym6fnM"
                  width="100%"
                  height="400"
                  frameborder="0"
                  allowfullscreen="allowfullscreen"
                />
                <p>
                  The manufacture of these wheels is a precise and tightly
                  controlled process, due not only to the inherent safety risks
                  of a spinning disc, but also the composition and uniformity
                  required to prevent that disc from exploding due to the high
                  stresses produced on rotation.
                </p>
                <p>
                  Grinding wheels are&nbsp;
                  <a
                    target="_blank"
                    href="https://en.wikipedia.org/wiki/Consumables"
                    rel="noopener"
                  >
                    consumables
                  </a>
                  , although the life span can vary widely depending on the use
                  case, from less than a day to many years. As the wheel cuts,
                  it periodically releases individual grains of abrasive,
                  typically because they grow dull and the increased drag pulls
                  them out of the bond. Fresh grains are exposed in this wear
                  process, which begin the next cycle. The rate of wear in this
                  process is usually very predictable for a given application,
                  and is necessary for good performance.<strong>&nbsp;</strong>
                </p>
                <img src={grindingWheel} alt="Use of Grinding Wheel" />
                <h2>
                  <strong>Use of Grinding Wheel:</strong>
                </h2>
                <p>
                  a wheel used for cutting, grinding, or finishing metal or
                  other objects, and typically made of abrasive particles bonded
                  together.<strong>&nbsp;</strong>
                </p>
                <h3>
                  <strong>Grinding Wheel Composition:</strong>
                </h3>
                <p>The Three Elements of Grinding Wheel</p>
                <h4>
                  <strong>
                    Grain:&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp;&nbsp;
                  </strong>
                </h4>
                <p>
                  Very hard, functions as the “cutting edge” in grinding work.
                </p>
                <h4>
                  <strong>Bond:</strong>
                </h4>
                <p>Bonds and holds abrasive grains.</p>
                <h4>
                  <strong>Pore:</strong>
                </h4>
                <p>
                  Functions as a “chip pocket” and helps cool the grinding
                  wheel.
                </p>
                <img
                  src={componentGrindingwheel}
                  alt="Component of Grinding wheel"
                />
                <p>
                  A grinding wheel consists of three elements: abrasive grain,
                  bond and pore, as shown in figure 1. As the wheel turns in
                  high speed, its innumerable grains quickly grind workpiece to
                  beautiful finish and precise measurement.
                </p>
                <h3>
                  <strong>Grinding Wheel Scrap:</strong>
                </h3>
                <p>
                  <Link to="./used-grinding-wheels/">
                    <strong>Grinding Wheel Scrap</strong>
                  </Link>{" "}
                  are the scrap. This&nbsp;
                  <Link to="./used-grinding-wheels/">
                    <strong>wheel scrap</strong>
                  </Link>
                  &nbsp;is use for making new{" "}
                  <Link to="./used-grinding-wheels/">
                    <strong>grinding wheels</strong>
                  </Link>
                  &nbsp;for fulfilling the needs of cutting operations This
                  Grinding wheel&nbsp;<strong>scrap</strong>&nbsp;has
                  exceptional finishing and is used in varied industrial and
                  commercial sectors.
                </p>
                <h3>
                  <strong>Where Do I Get a Grinding wheel Scrap?</strong>
                </h3>
                <p>
                  <Link to="./">
                    <strong>Sree Balamurugan Industries</strong>
                  </Link>{" "}
                  offering a Quality{" "}
                  <Link to="./used-grinding-wheels/">Grinding Wheel Scrap</Link>{" "}
                  all over the India. Our Scrap are best in Quality. We supply
                  Grinding wheels scrap based on the Clients Requirements.
                </p>
              </Col>
            </Row>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
