import React, { Component } from "react";
import { withRouter } from "react-router";
import { Row, Col } from "antd";

export default class IndustrySpecificRequirement extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="section-padding  cta">
          <div className="container">
            <Row gutter={[10, 20]} align="middle">
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
                <div className="bg-white p-4">
                  <h4>Industry specific requirements</h4>
                  <p>
                    {" "}
                    we have been able to meet the bulk assignments in the most
                    efficient manner. Quality focused and driven approach is
                    followed while processing the offered scrap in compliance
                    with the industry specific requirements. for more details{" "}
                  </p>
                  <span>Call Us: </span>
                  <a className="phone" href="tel: +91-9994906808">
                    +91-9994906808
                  </a>
                </div>
              </Col>
            </Row>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
