import React, { Component } from "react";
import { Row, Col } from "antd";
import TitleSection from "../../Components/TitleSection/TitleSection";
import IndustrySpecificRequirement from "../Home/IndustrySpecificRequirement/IndustrySpecificRequirement";
import MillScaleimg from "../../assets/images/mill-scale/mill-scale.jpg";
export default class MillScale extends Component {
  render() {
    return (
      <React.Fragment>
        <TitleSection className="millscale-bg" titleName="Mill Scale" />

        <section className="section-padding">
          <div className="container">
            <Row gutter={[10, 20]} align="middle">
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
                <h2 className="mb-4">Mill Scale</h2>
                <p>
                  <span>Mill scale</span> is the flaky surface of hot worked
                  steel and is one of the wastes generated from steel plants,
                  which get produced during the rolling of metal in various
                  metal extrusion industries, it represents 2% of the produced
                  steel.{" "}
                </p>
                <p>
                  Mill scale is formed on the outer surfaces of plates, sheets
                  or profiles when they are being produced by rolling red hot
                  iron or steel billets in rolling or steel mills. Mill scale is
                  composed of iron oxides mostly ferric and is bluish black in
                  colour. We supply the best quality mill scale.{" "}
                </p>

                <p>
                  We specialize in sourcing clean, ready to ship materials. We
                  are highly qualified mill scale sellers and the materials are
                  available. If you need a specific mill scale specification, we
                  can upgrade or blend the materials to your needs. The quality
                  of the mill scale which we sell will be in international
                  standards.
                </p>
                <p>
                  We offer superior quality Mill Scale, which are sourced from
                  well known mill scale factories.{" "}
                </p>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
                <img
                  src={MillScaleimg}
                  className="w-100"
                  alt="Mill Scale"
                  title="Mill Scale"
                />
              </Col>
            </Row>
          </div>
        </section>
        <IndustrySpecificRequirement />
      </React.Fragment>
    );
  }
}
