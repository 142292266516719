import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "antd";
import "./About.scss";
import AboutImage from "../../assets/images/about-sree-balamurugan-industries.png";

export default class AboutSection extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="section-padding">
          <div className="container">
            <Row gutter={[10, 20]} align="middle">
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 12 }}
              >
                <div class="px-lg-3 align-middle">
                  <h2 className="mb-3">About Us</h2>
                  <p>
                    Incorporated in the year 2014, Sri Balamurugan industries is
                    one of the reputed companies in the market. The head quarter
                    of our business is situated in Chennai, Tamil Nadu (India).
                    We are efficiently involved in trading and wholesaling a
                    wide range of products such as Cast iron Grinding Powder,
                    recycle Scrap, wastage Grinding wheel, wastage cut of wheel,
                    mill scale, MS Powder, Shots, Cuppy and many more. Offered
                    products are cost effective in nature.
                  </p>
                  <p>
                    Also we offer a wide range of industrial scrap. These
                    Recycle Scrap Products are processed by using advanced
                    machinery and equipment.
                  </p>
                  <p>
                    we have been able to meet the bulk assignments in the most
                    efficient manner. Quality focused and driven approach is
                    followed while processing the offered scrap in compliance
                    with the industry specific requirements.{" "}
                  </p>
                </div>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 12 }}
              >
                <img src={AboutImage} alt="" className="w-100" />
              </Col>
            </Row>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
