import React, { Component } from "react";
import { withRouter } from "react-router";
import { Row, Col } from "antd";
import TitleSection from "../../Components/TitleSection/TitleSection";
import ProductandServices from "../Home/ProductandServices/ProductandServices";

export default class ProductServices extends Component {
  render() {
    return (
      <React.Fragment>
        <TitleSection
          className="productservice-bg"
          welcomeText="WELCOME TO Sree Balamurugan Industries"
          titleName="Products & Services"
        />
        <ProductandServices />
      </React.Fragment>
    );
  }
}
