import React from 'react';
import HeaderNav from './Header';
import FooterNav from './Footer';

// const { Content } = Layout;

export default class Layouts extends React.Component {
  state = {
    collapsed: false,
  };
  componentDidUpdate() {
    window.scrollTo(0, 0);
  }
  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  render() {
    return (
      <div className="site-layout">
        <HeaderNav {...this.props} />
        <div>
          {this.props.children}
        </div>
        <FooterNav />
      </div>
    );
  }
}