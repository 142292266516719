import React, { Component } from "react";
import { Row, Col } from "antd";
import TitleSection from "../../Components/TitleSection/TitleSection";
import use from "../../assets/images/cast-iron-grinding-powder/use.svg";
import abrasive from "../../assets/images/cast-iron-grinding-powder/abrasive.svg";
import radioactive from "../../assets/images/cast-iron-grinding-powder/radioactive.svg";
import rusting from "../../assets/images/cast-iron-grinding-powder/rusting.svg";
import unmatchedQuality from "../../assets/images/cast-iron-grinding-powder/unmatched-quality.svg";
import weight from "../../assets/images/cast-iron-grinding-powder/weight.svg";
import castIron from "../../assets/images/cast-iron-grinding-powder/cast-iron-grinding-powder.jpg";
import IndustrySpecificRequirement from "../Home/IndustrySpecificRequirement/IndustrySpecificRequirement";

const BlogList = [
  {
    id: 1,
    title: "Easy to use",
    image: [use],
  },
  {
    id: 2,
    title: "Unmatched quality",
    image: [unmatchedQuality],
  },
  {
    id: 3,
    title: "Anti rusting",
    image: [rusting],
  },
  {
    id: 4,
    title: "non-abrasive nature",
    image: [abrasive],
  },
  {
    id: 5,
    title: "Light weight",
    image: [weight],
  },
  {
    id: 6,
    title: "non-radioactive",
    image: [radioactive],
  },
];

export default class CastironGrindingPowder extends Component {
  render() {
    return (
      <React.Fragment>
        <TitleSection
          className="castiron-grindingpowder-bg"
          titleName="Cast iron Grinding Powder"
        />

        <section className="section-padding">
          <div className="container">
            <Row gutter={[10, 20]} align="middle">
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
                <h2 className="mb-4">
                  Matching up with the exact requirements of customers, we are
                  involved in presenting wide array of Cast iron Grinding
                  Powder.
                </h2>
                <p>
                  Our provided grinding powder is free from any foreign
                  substance &amp; used in construction of various products and
                  has long lasting life. Offered grinding powder is widely
                  recognized in the industry for its best usage and can be
                  availed at affordable prices.
                </p>
                <p>
                  Perfect quality maintain for international &amp; domestic
                  clients. We match the material as per customer specification.
                  Available in different mesh size &amp; different purity &amp;
                  reactivity requirements.
                </p>
                <p>
                  Packing can be available as per customer requirements. just in
                  time shipment practise is always. very high purity and
                  adequate reactivity.
                </p>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
                <img
                  src={castIron}
                  className="w-100"
                  alt="Cast iron Grinding Powder"
                  title="Cast iron Grinding Powder"
                />
              </Col>
            </Row>
          </div>
        </section>
        <section className="section-padding bg-gray some-features">
          <div className="container">
            <Row gutter={[10, 20]} align="middle">
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
              >
                <h2 className="text-center mb-4">Some Of The Features</h2>
              </Col>
            </Row>

            <Row gutter={[10, 20]} align="middle">
              {BlogList.map((list, index) => (
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 4 }}
                  lg={{ span: 4 }}
                  data-index={index}
                >
                  <div class="text-center">
                    <img
                      src={`${list.image}`}
                      alt={`${list.title}`}
                      title={`${list.title}`}
                    />
                    <div className="details text-left">
                      <p>{list.title}</p>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </section>
        <IndustrySpecificRequirement />
      </React.Fragment>
    );
  }
}
