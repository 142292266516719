import React, { Component } from "react";
import { Row, Col } from "antd";
import TitleSection from "../../Components/TitleSection/TitleSection";
import iron from "../../assets/images/cast-iron-boring/iron.svg";
import meallable from "../../assets/images/cast-iron-boring/meallable.svg";
import melt from "../../assets/images/cast-iron-boring/melt.svg";
import recycling from "../../assets/images/cast-iron-boring/recycling.svg";
import rust from "../../assets/images/cast-iron-boring/rust.svg";
import IndustrySpecificRequirement from "../Home/IndustrySpecificRequirement/IndustrySpecificRequirement";
import castIronImg from "../../assets/images/cast-iron-boring/cast-iron-boring-scrap.jpg";

const FeatureList = [
  {
    id: 1,
    title: "High content of iron",
    image: [iron],
  },
  {
    id: 2,
    title: "Highly malleable",
    image: [meallable],
  },
  {
    id: 3,
    title: "Easy to melt",
    image: [melt],
  },
  {
    id: 4,
    title: "Reusable",
    image: [recycling],
  },
  {
    id: 5,
    title: "Rust free",
    image: [rust],
  },
];

export default class CastIronboring extends Component {
  render() {
    return (
      <React.Fragment>
        <TitleSection
          className="castironboring-bg"
          titleName="Cast Iron Boring"
        />

        <section className="section-padding">
          <div className="container">
            <Row gutter={[10, 20]} align="middle">
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
                <h2 className="mb-4">
                  Being an eminent organization in the industry, we are involved
                  in providing a premium quality range of Cast Iron Boring
                  Scrap.
                </h2>
                <p>
                  Also We Provide excellent quality Casting Iron Boring that can
                  avail from us in different specifications to meet the various
                  requirements of clients. The offered powder is widely used in
                  various industries like automobile, aerospace, electrical,
                  metallurgy, chemical, etc. Sourced from the genuine vendors of
                  the market.
                </p>

                <p>
                  Our company has been successful in winning appreciation from
                  the clients as one of the most prominent names in the trading
                  of Cast Iron Borings.
                </p>
                <p>
                  <span>Cast Iron Packaging option:</span> As per client’s
                  requirements
                </p>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
                <img
                  src={castIronImg}
                  className="w-100"
                  alt="Cast Iron Boring"
                  title="Cast Iron Boring"
                />
              </Col>
            </Row>
          </div>
        </section>
        <section className="section-padding bg-gray some-features">
          <div className="container">
            <Row gutter={[10, 20]} align="middle">
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
              >
                <h2 className="text-center mb-4">Some Of The Features</h2>
              </Col>
            </Row>

            <Row gutter={[10, 20]} align="center">
              {FeatureList.map((list, index) => (
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 4 }}
                  lg={{ span: 4 }}
                  data-index={index}
                >
                  <div class="text-center">
                    <img
                      src={`${list.image}`}
                      alt={`${list.title}`}
                      title={`${list.title}`}
                    />
                    <div className="details text-left">
                      <p>{list.title}</p>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </section>
        <IndustrySpecificRequirement />
      </React.Fragment>
    );
  }
}
