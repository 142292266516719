import React from "react";
import { Row, Col } from "antd";
import TitleSection from "../../Components/TitleSection/TitleSection";
import AboutSection from "./AboutSection";
import WhysreeBalamuruganIndustries from "../../assets/images/about/why-sree-balamurugan-industries.png";
import ProductandServices from "../Home/ProductandServices/ProductandServices";
class About extends React.Component {
  render() {
    return (
      <React.Fragment>
        <TitleSection
          className="about-bg"
          welcomeText="WELCOME TO Sree Balamurugan Industries"
          titleName="About Us"
        />

        <AboutSection />

        <section className="section-padding why-us">
          <div className="container">
            <Row gutter={[10, 20]} align="middle">
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 12 }}
              >
                <div class="align-middle bg-white p-4">
                  <h2 className="mb-3">Why Us?</h2>
                  <p>
                    We believe in making a long lasting association with our
                    patrons by following quality focused and customer centric
                    approach. Determined to attain maximum customer approval, we
                    assure timely delivery of the offered scrap at the patrons’
                    end. In addition to this, we are known for our ability to
                    meet the bulk demands.
                  </p>
                  <h3>Our Strength Is:</h3>
                  <ul>
                    <li>Well-equipped infrastructure</li>
                    <li>Dedicated professionals</li>
                    <li>Ability to meet the bulk demands</li>
                  </ul>
                </div>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 12 }}
              >
                <img
                  src={WhysreeBalamuruganIndustries}
                  alt="Why Sree balamurugan Industries"
                  className="w-100"
                />
              </Col>
            </Row>
          </div>
        </section>
        <ProductandServices />
      </React.Fragment>
    );
  }
}
export default About;
