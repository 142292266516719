import React, { Component } from "react";
import { Row, Col } from "antd";
import TitleSection from "../../Components/TitleSection/TitleSection";
import recycling from "../../assets/images/used-grinding-wheels/recycling.svg";
import strength from "../../assets/images/used-grinding-wheels/strength.svg";
import unmatchedQuality from "../../assets/images/used-grinding-wheels/unmatched-quality.svg";
import IndustrySpecificRequirement from "../Home/IndustrySpecificRequirement/IndustrySpecificRequirement";
import usedgrindingwheelImg from "../../assets/images/blog/best-grinding-wheel-scrap.jpg";

const FeatureList = [
  {
    id: 1,
    title: "Great strength",
    image: [strength],
  },
  {
    id: 2,
    title: "Unique quality",
    image: [unmatchedQuality],
  },
  {
    id: 3,
    title: "Easy to Recycle",
    image: [recycling],
  },
];

export default class UsedGrindingwheels extends Component {
  render() {
    return (
      <React.Fragment>
        <TitleSection
          className="usedgrindingwheel-bg"
          titleName="Used Grinding wheels"
        />

        <section className="section-padding">
          <div className="container">
            <Row gutter={[10, 20]} align="middle">
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
                <h2 className="mb-4">
                  Used Grinding Wheels For Recycling Purpose
                </h2>
                <p>
                  Our company excels in offering Used Grinding Wheel (Grinding
                  Wheel Scrap) to the clients. This wheel scrap is use for
                  making new grinding wheels for fulfilling the needs of cutting
                  stones, building material and in masonry applications.{" "}
                </p>

                <p>
                  Our offered scrap has exceptional finishing and is used in
                  varied industrial and commercial sectors. We offer this scrap
                  in various quantities as per the needs of our clients in a
                  given time
                </p>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
                <img
                  src={usedgrindingwheelImg}
                  className="w-100"
                  alt="Used Grinding wheels"
                  title="Used Grinding wheels"
                />
              </Col>
            </Row>
          </div>
        </section>
        <section className="section-padding bg-gray some-features">
          <div className="container">
            <Row gutter={[10, 20]} align="middle">
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
              >
                <h2 className="text-center mb-4">Some Of The Features</h2>
              </Col>
            </Row>

            <Row gutter={[10, 20]} align="center">
              {FeatureList.map((list, index) => (
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 4 }}
                  lg={{ span: 4 }}
                  data-index={index}
                >
                  <div class="text-center">
                    <img
                      src={`${list.image}`}
                      alt={`${list.title}`}
                      title={`${list.title}`}
                    />
                    <div className="details text-left">
                      <p>{list.title}</p>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </section>
        <IndustrySpecificRequirement />
      </React.Fragment>
    );
  }
}
