import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { Row, Col } from "antd";
import TitleSection from "../../Components/TitleSection/TitleSection";
import "./ContactUs.scss";
import ContactForm from "./ContactForm";
import email from "../../assets/images/icons/email.png";
import location from "../../assets/images/icons/location.png";
import phone from "../../assets/images/icons/phone.png";

class ContactUs extends React.Component {
  render() {
    return (
      <React.Fragment>
        <TitleSection
          className="contact-bg"
          welcomeText="WELCOME TO Sree Balamurugan Industries"
          titleName="Contact Us"
        />
        <section className="get-in-touch py-5">
          <div className="container">
            <Row gutter={[30, 20]} align="center" className="pb-3">
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 20 }}
                lg={{ span: 12 }}
                className="center-contents"
              >
                <div className="contact-details mb-4">
                  <h2>Contact Information</h2>
                  <div className="list">
                    <img src={location} alt="location" />
                    <div className="details">
                      <span className="d-block">Office Location</span>
                      <p>
                        No. 44/3B, Keelmeni Village,{" "}
                        <span className="d-block">
                          Chinnambedu Post, Ponneri Taluk,
                        </span>{" "}
                        Thiruvallur Dist, Tamilnadu - 601206
                      </p>
                    </div>
                  </div>
                  <div className="list">
                    <img src={phone} alt="Phone" />
                    <div className="details">
                      <span className="d-block">Phone Numbers</span>
                      <p>
                        <a href="tel:+91-9994906808">+91-9994906808</a>
                      </p>
                      <p>
                        <a href="tel:+91-9443110534"> +91-9443110534</a>
                      </p>
                      <p>
                        <a href="tel:+91-9952751032">+91-9952751032</a>
                      </p>
                    </div>
                  </div>
                  <div className="list">
                    <img src={email} alt="email" />
                    <div className="details">
                      <span className="d-block">Email Address</span>
                      <p>
                        <a href="mailto:dsankar.sbi@gmail.com">
                          dsankar.sbi@gmail.com
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 20 }}
                lg={{ span: 12 }}
                className="center-contents"
              >
                <p className="mt-4">
                  Fill out the form below and we will contact you as soon as
                  possible.
                </p>
                <div className="title-section text-center mb-4">
                  <ContactForm />
                </div>
              </Col>
            </Row>
          </div>
        </section>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3882.0701126786776!2d80.15741541417196!3d13.3459147101666!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a527f9b66bc7d9f%3A0xdcce2e9341974c2f!2sSree+Balamurugan+Industries!5e0!3m2!1sen!2sin!4v1534423249226"
          width="100%"
          height="350"
          frameborder="0"
          allowfullscreen=""
        />
      </React.Fragment>
    );
  }
}
export default withRouter(ContactUs);
