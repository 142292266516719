import React, { Component } from "react";
import { withRouter } from "react-router";
import { Row, Col } from "antd";
import castIronBoring from "../../../assets/images/home/cast-iron-grinding-powder.jpg";
import AllScrapMaterials from "../../../assets/images/home/metal-recyclable-scrap.jpg";
import UsedGrindingWheels from "../../../assets/images/home/used-grinding-wheel.jpg";
import MillScale from "../../../assets/images/home/mill-scale-supplier.jpg";
import CastIronBoring from "../../../assets/images/home/cast-iron-boring-scrap.jpg";
import { Link } from "react-router-dom";

const ProductData = [
  {
    id: 1,
    title: "Cast iron Grinding Powder",
    image: [castIronBoring],
    link: "./cast-iron-grinding-powder",
  },
  {
    id: 2,
    title: "All Scrap Materials",
    image: [AllScrapMaterials],
    link: "./all-scrap-materials",
  },
  {
    id: 3,
    title: "Used Grinding wheels",
    image: [UsedGrindingWheels],
    link: "./used-grinding-wheels",
  },
  {
    id: 4,
    title: "Mill Scale",
    image: [MillScale],
    link: "./mill-scale",
  },
  {
    id: 5,
    title: "Cast Iron Boring",
    image: [CastIronBoring],
    link: "./cast-iron-boring",
  },
];

export default class ProductandServices extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="section-padding products">
          <div className="container">
            <Row gutter={[10, 20]} align="middle">
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
                className="text-center mb-2"
              >
                <h2>Our Products and Services</h2>
              </Col>
            </Row>
            <Row gutter={[10, 20]} align="middle">
              {ProductData.map((list, index) => (
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 8 }}
                  lg={{ span: 8 }}
                  data-index={index}
                >
                  <Link to={`${list.link}`}>
                    <div class="card">
                      <img
                        src={`${list.image}`}
                        alt={`${list.title}`}
                        title={`${list.title}`}
                      />
                      <div className="details">
                        <p>{list.title}</p>
                      </div>
                    </div>
                  </Link>
                </Col>
              ))}
            </Row>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
