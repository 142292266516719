import React from "react";
import { withRouter } from "react-router";
import Slider from "react-slick";
import "./HomeSlider.scss";

// slider component
const mainSlider = {
  arrows: true,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        arrows: false,
      },
    },
    {
      breakpoint: 900,
      settings: {
        arrows: false,
      },
    },
    {
      breakpoint: 580,
      settings: {
        arrows: false,
      },
    },
    {
      breakpoint: 400,
      settings: {
        arrows: false,
      },
    },
  ],
};

const SliderData = [
  {
    id: 1,
    title: "Best Quality Cast iron Grinding Powder",
    class: "slider-1",
    link: "",
  },
  {
    id: 2,
    title: "Used Grinding Wheels for Recycling Purpose",
    class: "slider-2",
    link: "",
  },
  {
    id: 3,
    title: "All kind of Scrap Materials",
    class: "slider-3",
    link: "",
  },
  {
    id: 4,
    title: "Best Quality Mill Scale Supplier",
    class: "slider-4",
    link: "",
  },
];
class HomeSlider extends React.Component {
  render() {
    return (
      <React.Fragment>
        {/* <!--Main Slider section starts--> */}
        <div className="sbi-slider" id="home">
          <div className="slider-content ">
            <Slider {...mainSlider}>
              {SliderData.map((list, index) => (
                <div data-index={index}>
                  <div className={`${list.class} items`}>
                    <div className="center-contents w-100 p-mob">
                      <div className=" middle-content ">
                        <div className="align-middle slider-caption">
                          <h1 className="font-weight-normal text-white text-center  mb-3">
                            WELCOME TO Sree Balamurugan Industries
                          </h1>
                          <p className="text-white text-center text-uppercase ">
                            {list.title}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        {/* <!--Main Slider section ends--> */}
      </React.Fragment>
    );
  }
}
export default HomeSlider;
