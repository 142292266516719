import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "../Screens/Home/Home";
import Layout from "../Layout/Layout";
import About from "../Screens/About/About";
import TermsandConditions from "../Screens/TermsandConditions/TermsandConditions";
import PrivacyPolicy from "../Screens/PrivacyPolicy/PrivacyPolicy";
import ContactUs from "../Screens/ContactUs/ContactUs";
import ProductsServices from "../Screens/ProductsServices/ProductsServices";
import Blog from "../Screens/Blog/Blog";
import whatisGrindingwheel from "../Screens/Blog/whatisGrindingwheel";
import CastironGrindingPowder from "../Screens/CastironGrindingPowder/CastironGrindingPowder";
import AllScrapMaterials from "../Screens/AllScrapMaterials/AllScrapMaterials";
import CastIronboring from "../Screens/CastIronboring/CastIronboring";
import UsedGrindingwheels from "../Screens/UsedGrindingwheels/UsedGrindingwheels";
import ErrorPage from "../Screens/ErrorPage/ErrorPage";
import MillScale from "../Screens/MillScale/MillScale";

class InnerRoutes extends React.Component {
  render() {
    const {
      match: { url },
    } = this.props;
    return (
      <Layout {...this.props}>
        <Switch>
          <Route exact path={`/`} component={Home} />
          <Route path="/about-us" component={About} />
          <Route path="/terms-and-conditions" component={TermsandConditions} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/contact" component={ContactUs} />
          <Route path="/products-services" component={ProductsServices} />
          <Route path="/blog" component={Blog} />
          <Route
            path="/what-is-grinding-wheel-and-its-uses"
            component={whatisGrindingwheel}
          />
          <Route
            path="/cast-iron-grinding-powder"
            component={CastironGrindingPowder}
          />
          <Route path="/all-scrap-materials" component={AllScrapMaterials} />
          <Route path="/mill-scale" component={MillScale} />
          <Route path="/cast-iron-boring" component={CastIronboring} />
          <Route path="/used-grinding-wheels" component={UsedGrindingwheels} />
          <Route path={"*"} component={ErrorPage} />
        </Switch>
      </Layout>
    );
  }
}

export default InnerRoutes;
