import { Col, Row } from "antd";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import errorImg from "../../assets/images/error.svg";
import TitleSection from "../../Components/TitleSection/TitleSection";

export default class ErrorPage extends Component {
  render() {
    return (
      <React.Fragment>
        <TitleSection
          className="error-bg"
          welcomeText="WELCOME TO Sree Balamurugan Industries"
          titleName="Page Not Found!"
        />
        <section className="section-padding">
          <div className="container">
            <Row gutter={[12, 22]} align="middle">
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
                <h1>Sorry! Page Not Found!</h1>
                <p>
                  Oops! The page which you are looking for does not exist.
                  Please return to the homepage.
                </p>
                <Link to={"/"} className="btn btn-primary">
                  Back to Home Page
                </Link>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
                <img
                  src={errorImg}
                  alt="error 404 page not found"
                  className="w-100"
                />
              </Col>
            </Row>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
