import React, { Component } from "react";
import { Row, Col } from "antd";
import TitleSection from "../../Components/TitleSection/TitleSection";
import AllScrap from "../../assets/images/allscrap-materials/recyclable-scrap.jpg";
import IndustrySpecificRequirement from "../Home/IndustrySpecificRequirement/IndustrySpecificRequirement";

export default class AllScrapMaterials extends Component {
  render() {
    return (
      <React.Fragment>
        <TitleSection className="allscrap-bg" titleName="All Scrap Materials" />

        <section className="section-padding">
          <div className="container">
            <Row gutter={[10, 20]} align="middle">
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
                <h2 className="mb-4">All Scrap Materials</h2>
                <p>
                  <span>Scrap</span> consists of recyclable materials left over
                  from product manufacturing and consumption, such as parts of
                  vehicles, building supplies, and surplus materials. Unlike
                  waste, scrap has monetary value, especially recovered metals,
                  and non-metallic materials are also recovered for recycling.
                </p>
                <p>
                  A scrap metal shredder is often used to recycle items
                  containing a variety of other materials in combination with
                  steel
                </p>
                <p>
                  We Are the Leading Supplier of the Iron Scrap in Huge
                  Quantity. We Can Supply to any Place of India
                </p>
                <p>
                  We offer Iron Scrap at reasonable rates. These are highly
                  demanded.{" "}
                </p>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
              >
                <img
                  src={AllScrap}
                  className="w-100"
                  alt="All Scrap Materials"
                  title="All Scrap Materials"
                />
              </Col>
            </Row>
          </div>
        </section>
        <IndustrySpecificRequirement />
      </React.Fragment>
    );
  }
}
