import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, BackTop } from "antd";
import "./Footer.scss";
import mainlogo from "../assets/images/footer-logo.svg";
import moveTop from "../assets/images/up-arrow.svg";
export default class Footer extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section className="footer py-5" id="contact">
          <div className="container">
            <Row>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 6 }}
                lg={{ span: 6 }}
              >
                <div className="footer-logo mb-3 mb-lg-5">
                  <img src={mainlogo} className="pr-4 mr-4" alt="WW Infra" />
                </div>
                <div className="social-media">
                  <h3 className="text-red text-uppercase">Follow us</h3>
                  <ul className="d-flex p-0 m-0">
                    <li>
                      <Link to="#" target="_blank" rel="noopener noreferrer">
                        <i className="fa fa-facebook" aria-hidden="true" />
                      </Link>
                    </li>
                    <li>
                      <Link to="#" target="_blank" rel="noopener noreferrer">
                        <i className="fa fa-instagram" aria-hidden="true" />
                      </Link>
                    </li>
                    <li>
                      <Link to="#" target="_blank" rel="noopener noreferrer">
                        <i className="fa fa-twitter" aria-hidden="true" />
                      </Link>
                    </li>
                    <li>
                      <Link to="#" target="_blank" rel="noopener noreferrer">
                        <i
                          className="fa fa-linkedin-square"
                          aria-hidden="true"
                        />
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 6 }}
                lg={{ span: 6 }}
              >
                <div class="resfooter">
                  <h3>Quick Links</h3>
                  <ul>
                    <li>
                      <Link to={"./"}>Home</Link>
                    </li>
                    <li>
                      <Link to={"./about-us"}>About Us</Link>
                    </li>
                    <li>
                      <Link to={"./products-services"}>
                        Products & Services
                      </Link>
                    </li>
                    <li>
                      <Link to={"./contact"}>Contact</Link>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 6 }}
                lg={{ span: 6 }}
              >
                <div class="resfooter">
                  <h3>Products & Services</h3>
                  <ul>
                    <li>
                      <Link to={"./cast-iron-grinding-powder"}>
                        Cast iron Grinding Powder
                      </Link>
                    </li>
                    <li>
                      <Link to={"./all-scrap-materials"}>
                        All Scrap Materials
                      </Link>
                    </li>
                    <li>
                      <Link to={"./used-grinding-wheels"}>
                        Used Grinding wheels
                      </Link>
                    </li>
                    <li>
                      <Link to={"./mill-scale"}>Mill Scale</Link>
                    </li>
                    <li>
                      <Link to={"./cast-iron-boring"}>Cast Iron Boring</Link>
                    </li>
                  </ul>
                </div>
              </Col>

              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 6 }}
                lg={{ span: 6 }}
              >
                <div class="resfooter">
                  <h3>More Links</h3>
                  <ul>
                    <li>
                      <Link to="/about-us">About Us</Link>
                    </li>
                    <li>
                      <Link to="/blog">blog</Link>
                    </li>
                    <li>
                      <Link to="/terms-and-conditions">
                        Terms and Condition
                      </Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 6 }}
                lg={{ span: 6 }}
              >
                <div className="contact-item mt-sm-2 mt-md-3 mt-lg-4">
                  <div className="icon">
                    <i className="fa fa-envelope" aria-hidden="true" />
                  </div>
                  <div className="details">
                    <span>Call Us</span>
                    <p>
                      <a href="tel:+91-9994906808">+91-9994906808</a>
                    </p>
                  </div>
                </div>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 6 }}
                lg={{ span: 6 }}
              >
                <div className="contact-item mt-sm-2 mt-md-3 mt-lg-4">
                  <div className="icon">
                    <i className="fa fa-envelope" aria-hidden="true" />
                  </div>
                  <div className="details">
                    <span>Email us</span>
                    <p>
                      <a href="mailto:dsankar.sbi@gmail.com">
                        dsankar.sbi@gmail.com
                      </a>
                    </p>
                  </div>
                </div>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 6 }}
                lg={{ span: 6 }}
              >
                <div className="contact-item  mt-sm-2 mt-md-3 mt-lg-4">
                  <div className="icon">
                    <i className="fa fa-clock-o" aria-hidden="true" />
                  </div>
                  <div className="details">
                    <span>Working Hours</span>
                    <p className="text-white">
                      Mon-Sat 9:00AM - 6:00 PM (IST){" "}
                    </p>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="text-center pt-3 border-t mt-3">
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
              >
                <p className="text-white mb-0 pt-3">
                  <span>
                    © {new Date().getFullYear()} Copyright Sree Balamurugan
                    Industries. All Right Reserved.
                  </span>
                </p>
              </Col>
            </Row>
          </div>
        </section>

        <BackTop>
          <div className="back-to-top">
            <img src={moveTop} alt="move to top" />
            <span className="d-block">UP</span>
          </div>
        </BackTop>
      </React.Fragment>
    );
  }
}
