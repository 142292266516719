import React from "react";
import { Row, Col } from "antd";
import { Link } from "react-router-dom";
import HomeSlider from "./HomeSlider/HomeSlider";
import AboutSection from "../About/AboutSection";
import "./Home.scss";
import ProductandServices from "./ProductandServices/ProductandServices";
import IndustrySpecificRequirement from "./IndustrySpecificRequirement/IndustrySpecificRequirement";
export default class Home extends React.Component {
  handlePageChange = (number) => {
    this.setState({ currentPage: number });
  };

  handleBeforePageChange = (number) => {
    console.log(number);
  };
  render() {
    return (
      <React.Fragment>
        <HomeSlider />

        <AboutSection />

        <section className="service-grid">
          <Row align="middle">
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
            >
              <div className="cast-iron-bg">
                <div class="align-middle">
                  <h2 className="mb-3">Cast iron Grinding Powder</h2>
                  <p>
                    Matching up with the exact requirements of customers, we are
                    involved in presenting wide array of Cast iron Grinding
                    Powder. Our provided grinding powder is free from any
                    foreign substance & used in construction of various products
                    and has long lasting life.
                  </p>
                  <Link to={"./cast-iron-grinding-powder"}>
                    Know More <i class="fa fa-long-arrow-right" />
                  </Link>
                </div>
              </div>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
            >
              <div className="all-scrap-bg">
                <div class="align-middle">
                  <h2 className="mb-3">All Scrap Materials</h2>
                  <p>
                    Scrap consists of recyclable materials left over from
                    product manufacturing and consumption, such as parts of
                    vehicles, building supplies, and surplus materials.
                  </p>
                  <Link to={"./all-scrap-materials"}>
                    Know More <i class="fa fa-long-arrow-right" />
                  </Link>
                </div>
              </div>
              <div className="used-grinding-bg">
                <div class="align-middle">
                  <h2 className="mb-3">Used Grinding wheel</h2>
                  <p>
                    Our company excels in offering Used Grinding Wheel (Grinding
                    Wheel Scrap) to the clients. This wheel scrap is use for
                    making new grinding wheels for fulfilling the needs of
                    cutting stones, building material and in masonry
                    applications.
                  </p>
                  <Link to={"./used-grinding-wheels"}>
                    Know More <i class="fa fa-long-arrow-right" />
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </section>

        <ProductandServices />

        <IndustrySpecificRequirement />
      </React.Fragment>
    );
  }
}
