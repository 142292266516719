import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "antd";
import TitleSection from "../../Components/TitleSection/TitleSection";
import "./Blog.scss";
import blogOne from "../../assets/images/blog/best-grinding-wheel-scrap.jpg";

const BlogList = [
  {
    id: 1,
    title: "What is Grinding wheel and it’s Uses ?",
    shortDescription:
      "A grinding wheel is a wheel composed of an abrasive compound and used for various grinding (abrasive cutting) and abrasive machiningoperations. Such wheels are used in grinding machines. The wheels are generally made from a composite material consisting of coarse-particle",
    image: [blogOne],
    link: "/what-is-grinding-wheel-and-its-uses",
  },
];

export default class Blog extends Component {
  render() {
    return (
      <React.Fragment>
        <TitleSection
          className="blog-bg"
          welcomeText="WELCOME TO Sree Balamurugan Industries"
          titleName="Blog - Recent Posts"
        />

        <section className="section-padding blog">
          <div className="container">
            <Row gutter={[10, 20]} align="middle">
              {BlogList.map((list, index) => (
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 8 }}
                  lg={{ span: 8 }}
                  data-index={index}
                >
                  <Link to={`${list.link}`}>
                    <div class="card">
                      <img
                        src={`${list.image}`}
                        alt={`${list.title}`}
                        title={`${list.title}`}
                      />
                      <div className="details text-left">
                        <h3>{list.title}</h3>
                        <p>{list.shortDescription}</p>
                        <a></a>CONTINUE READING
                      </div>
                    </div>
                  </Link>
                </Col>
              ))}
            </Row>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
